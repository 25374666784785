<template>
  <section >
    <section class="d-flex flex-wrap justify-space-between">
      <SearchBar 
        :placeholder="'Search class'" 
        :value.sync="search" 
        @clear="() => {
          search = '',
          page = 1,
          getClasses()
        }" 
        @search="() => {
          page = 1,
          getClasses()
        }" 
        class="mb-5" />
      <v-menu :value="shown" offset-y transition="slide-y-transition" 
        :close-on-content-click="false" 
        content-class="elevation-2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mb-3 text-capitalize poppins f12 ml-auto" 
            outlined 
            color="primary"
            v-bind="attrs"
            v-on="on"
            dense
          >
            <v-icon left>mdi-plus</v-icon>
            Add Class
          </v-btn>
        </template>
        <v-card >
          <v-card-text>
            <v-form ref="form">
              <label class="caption">GENERATE CLASS CODE *</label>
              <v-tooltip v-model="show" top >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on" >
                    <v-icon color="grey lighten-1" small>
                      mdi-information
                    </v-icon>
                  </v-btn>
                </template>
                <span>This is an auto generated and cannot be customized.</span>
              </v-tooltip>
              <div class="d-flex mb-1">
                <div class="flex-grow-1">
                  <FormTextFieldRequired
                    :value.sync="form.class_code"
                    :error="errs.class_code"
                    :readonly="true"
                  />
                </div>
                <div class="">
                  <v-btn color="primary" flat elevation="0" class="poppins fw500 f12" width="100" style="height: 40px;border-radius: 0px 5px 5px 0px; margin-left: -5px"
                  @click="random">Get Code</v-btn>
                </div>
              </div>
              <FormTextFieldRequired
                :label="'CREATE CLASS NAME *'"
                :value.sync="form.class_name"
                :error="errs.class_name"
              />
            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn :loading="saving" color="primary" block class="text-capitalize poppins" @click="generateClass">
              Generate
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </section>
    <section class="text-right">
      <FormLabel :label="`${totalCount} result/s`"/>
    </section>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="classes"
        class="poppins"
        :loading="loading"
        :footer-props="{
            'items-per-page-options': itemsPerPageOptions
        }"
        :page="page"
        :server-items-length="totalCount"
        @pagination="(e) => {
            page = e.page
            paginate = String(e.itemsPerPage),
            getClasses()
        }"
      >
        <template v-slot:item.action="{ item }">
          <v-btn icon color="primary" @click="open(item)">
            <v-icon small>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="selectedClass = item, deletedialog=true">
            <v-icon small>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- <FormPagination 
        :pageCount="pageCount" 
        :page="page"
        :paginate="paginate"
        @page="(e) => { page = e, getClasses() }" 
        @paginate="(e) => { paginate = e, page = 1, getClasses() }"/> -->
    </v-card>
    <ClassCourseModalVue :modal="modal" @close="close" :selectedClass="selectedClass"/>
    <delete-toast 
      :dialog="deletedialog"
      :deleting="deleteLoading"
      type="Class"
      @cancel="deletedialog=false"
      @delete="deleteClass"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import ClassCourseModalVue from '@/components/admin/course/ClassCourseModal.vue'

export default {
  components: { ClassCourseModalVue },
  data: () => ({
    toast: false,
    text: '',
    loading: false,
    deleteLoading: false,
    saving: false,
    search: '',
    shown: null,
    show: false,
    form: { class_code: '', class_name: '' },
    modal: false,
    selectedClass: {
      class_code: '',
      courses: []
    },
    chars: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    errs: [],
    deletedialog: false,
    pageCount: 1,
    page: 1,
    paginate: '10',
    totalCount: 0,
    headers: [
      {
        text: 'Class Name',
        value: 'class_name',
        align: 'start',
        sortable: false
      },
      {
        text: 'Class Code',
        value: 'class_code',
        align: 'center',
        sortable: false
      },
      {
        text: 'Enrolled Users',
        value: 'enrollee_count',
        align: 'center',
        sortable: false
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        sortable: false
      },
    ],
    itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
  }),
  computed: {
    ...mapState('admin', {
      classes: (state) => state.classes,
    }),

    ...mapState({
      errors: (state) => state.errors,
    }),

    itemsPerPage(){
      return Number(this.paginate)
    }
  },
  mounted() {
    this.getClasses()
  },
  methods: {
    ...mapActions('admin', [
      'addClassAction',
      'deleteClassAction',
      'getClassesAction'
    ]),
    ...mapMutations(['alertMutation']),

    getClasses(){
      if(!this.loading) {
        this.loading = true
        this.getClassesAction({page: this.page, paginate: Number(this.paginate), search: this.search}).then(res => {
          this.loading=false
          this.pageCount = res.last_page
          this.totalCount = res.total
          this.page = res.current_page
        }).catch(() => {
          this.loading = false
          this.classes = []
          this.pageCount = 0
          this.totalCount = 0
          this.alertMutation({
            show: true,
            text: 'Something went wrong',
            type: "error"
          })
        })
      }
    },

    saveClass() {
      this.alertMutation({
          show: true,
          text: 'Successfully added a new class',
          type: "success"
      })
    },

    open(item) {
      this.selectedClass = item
      this.modal = true
    },

    close(){
      this.modal = false
    },

    deleteClass(){
      this.deleteLoading = true
      this.deleteClassAction(this.selectedClass).then(()=>{
          this.deletedialog = false
          this.deleteLoading = false
          this.selectedClass = null
          this.alertMutation({
            show: true,
            text: 'Class successfully deleted',
            type: "success"
          })
          this.getClasses()
      }).catch(() => {
        this.deletedialog = false
        this.deleteLoading = false
        this.alertMutation({
            show: true,
            text: 'Something went wrong in deleting.',
            type: "error"
        })
      })
    },

    generateClass() {
      this.errs = []
      if(this.$refs.form.validate()){
        this.saving=true
        this.addClassAction(this.form).then(() => {
          this.saving=false
          this.shown = false
          this.errs=[]
          this.form = {
            class_code: '',
            class_name: ''
          }
          this.getClasses()
          setTimeout(() => {
            this.shown = null
          }, 1000);
        }).catch(() => {
          this.saving = false
          if(this.$errors()) return this.errs = this.errors
        })
      }
    },
    
    random() {
      this.form.class_code = this.randomGenerator(5, this.chars)
    },

    randomGenerator(length, chars) {
      let res = ''
      for (var i = length; i > 0; --i) res += chars[Math.round(Math.random() * (chars.length - 1))];
      return res;
    },
  }
}
</script>
